import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {RootState} from "../../../../../../store/store"

const bulkChargerDetailsSlice = createSlice({
    name: "bulkChargerDetails",
    initialState: {acChargers: [], dcChargers: []} as {
        acChargers: []
        dcChargers: []
    },
    reducers: {
        setACConnectorsDetails: (state, {payload: {acChargers}}: PayloadAction<{acChargers: []}>) => {
            state.acChargers = acChargers
        },
        setDCConnectorsDetails: (state, {payload: {dcChargers}}: PayloadAction<{dcChargers: []}>) => {
            state.dcChargers = dcChargers
        },
    },
})

export const {setACConnectorsDetails} = bulkChargerDetailsSlice.actions
export const {setDCConnectorsDetails} = bulkChargerDetailsSlice.actions

export default bulkChargerDetailsSlice.reducer
export const getAcChargersDetails = (state: RootState) => state.bulkChargerDetails.acChargers
export const getDcChargersDetails = (state: RootState) => state.bulkChargerDetails.dcChargers
