import {configureStore} from "@reduxjs/toolkit"
import auth from "../pages/Login/authSlice"
import common from "../pages/Common/commonSlice"
import toast from "../components/Toaster/ToasterSlice"
import error404 from "../components/Table/404/Error404Slice"
import error500 from "../components/Table/500/Error500Slice"
import user from "../pages/CompanyManagement/features/Profile/userSlice"
import stationDetails from "../pages/Locations/Services/StationDetailsSlice"
import chargerDetails from "../pages/Locations/Services/ChargerDetailsSlice"
import bulkChargerDetails from "../pages/Locations/Features/Chargers/Features/Services/bulkChargersSlice"
import {rtkQueryErrorLogger} from "./ErrorLogger"
import {mainSplitApi} from "../api"

export const store = configureStore({
    reducer: {
        [mainSplitApi.reducerPath]: mainSplitApi.reducer,
        auth,
        toast,
        error404,
        user,
        common,
        error500,
        stationDetails,
        chargerDetails,
        bulkChargerDetails,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
            .concat(mainSplitApi.middleware)
            .concat(rtkQueryErrorLogger),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
